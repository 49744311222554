@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap);
:root {
  /* spacing values */

  --space-unit: 1.3em;

  --space-none: calc(0 * var(--space-unit));

  --space-xxxs: calc(0.25 * var(--space-unit));

  --space-xxs: calc(0.375 * var(--space-unit));

  --space-xs: calc(0.5 * var(--space-unit));

  --space-sm: calc(0.75 * var(--space-unit));

  --space-md: calc(1.25 * var(--space-unit));

  --space-lg: calc(2 * var(--space-unit));

  --space-xl: calc(3.25 * var(--space-unit));

  --space-xxl: calc(5.25 * var(--space-unit));

  --space-xxxl: calc(8.5 * var(--space-unit));

  --space-hg: calc(12.25 * var(--space-unit));

  /* components padding */

  --component-padding: var(--space-sm);

  --radius: 4px;
}

/* vertical margins */

.margin-top--none {
  margin-top: 0em;
}
.margin-top,
.margin-top--md {
  margin-top: calc(1.25 * 1.3em);
  margin-top: var(--space-md);
}

.margin-top--xxxs {
  margin-top: calc(0.25 * 1.3em);
  margin-top: var(--space-xxxs);
}

.margin-top--xxs {
  margin-top: calc(0.375 * 1.3em);
  margin-top: var(--space-xxs);
}

.margin-top--xs {
  margin-top: calc(0.5 * 1.3em);
  margin-top: var(--space-xs);
}

.margin-top--sm {
  margin-top: calc(0.75 * 1.3em);
  margin-top: var(--space-sm);
}

.margin-top--lg {
  margin-top: calc(2 * 1.3em);
  margin-top: var(--space-lg);
}

.margin-top--xl {
  margin-top: calc(3.25 * 1.3em);
  margin-top: var(--space-xl);
}

.margin-top--xxl {
  margin-top: calc(5.25 * 1.3em);
  margin-top: var(--space-xxl);
}

.margin-top--xxxl {
  margin-top: calc(8.5 * 1.3em);
  margin-top: var(--space-xxxl);
}

.margin-bottom--none {
  margin-bottom: 0em;
}

.margin-bottom,
.margin-bottom--md {
  margin-bottom: calc(1.25 * 1.3em);
  margin-bottom: var(--space-md);
}

.margin-bottom--xxxs {
  margin-bottom: calc(0.25 * 1.3em);
  margin-bottom: var(--space-xxxs);
}

.margin-bottom--xxs {
  margin-bottom: calc(0.375 * 1.3em);
  margin-bottom: var(--space-xxs);
}

.margin-bottom--xs {
  margin-bottom: calc(0.5 * 1.3em);
  margin-bottom: var(--space-xs);
}

.margin-bottom--sm {
  margin-bottom: calc(0.75 * 1.3em);
  margin-bottom: var(--space-sm);
}

.margin-bottom--lg {
  margin-bottom: calc(2 * 1.3em);
  margin-bottom: var(--space-lg);
}

.margin-bottom--xl {
  margin-bottom: calc(3.25 * 1.3em);
  margin-bottom: var(--space-xl);
}

.margin-bottom--xxl {
  margin-bottom: calc(5.25 * 1.3em);
  margin-bottom: var(--space-xxl);
}

.margin-bottom--xxxl {
  margin-bottom: calc(8.5 * 1.3em);
  margin-bottom: var(--space-xxxl);
}

:root {
  /* COLOURS */
  --primary: #0060e8;
  --primary-hovered: #0459d1;
  --secondary: #162447;
  --success: #0aaa65;
  --success-hovered: #078d53;
  --warning: #f7a440;
  --warning-hovered: #d88929;
  --danger: #ef233c;
  --danger-hovered: #a0031d;

  --light-purple: #da77f2;
  --purple: #ae3ec9;
  --dark-purple: #862e9c;

  --neutral-100: #ffffff;
  --neutral-150: #fafafa;
  --neutral-200: #f5f6f8;
  --neutral-300: #e6e9ef;
  --neutral-350: #d7d8dd;
  --neutral-400: #c5c7d0;
  --neutral-450: #929292;
  --neutral-500: #323338;
  --neutral-600: #242424;

  --text-light: var(--neutral-100);
  --text-grey: #676879;
  --text-dark: var(--neutral-500);
  --text-primary: var(--primary);
}

/* --------------------------------
Typography
-------------------------------- */

:root {
  --font-1: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  --font-2: "IBM Plex Sans", "Segoe UI Webfont", -apple-system, sans-serif;

  --font-3: "Kanit", sans-serif;

  --font-4: "Inter", sans-serif;

  --font-primary: var(--font-4);
  --font-secondary: var(--font-4);
  --font-fallback: var(--font-primary);

  --color-link: var(--accent-color-darker);
  --color-text: var(--secondary-color);

  /* set base values */
  --text-base-size: 1em;
  --text-scale-ratio: 1.28;
  --baseline: 20px;

  --space-sm: calc(var(--baseline) / 2);
  --space-md: var(--baseline);
  --space-lg: calc(var(--baseline) * 2);
  --space-xl: calc(var(--baseline) * 3);

  /* type scale */
  --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
  --text-sm: calc(1em / var(--text-scale-ratio));
  --text-rg: calc(1em * var(--text-scale-ratio) + var(--text-scale-ratio));
  --text-md: calc(1em * var(--text-scale-ratio));
  --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio)
  );
  --text-xxl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-xxxl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio)
  );

  /* line-height */
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

body {
  font-size: 1em;
  font-size: var(--text-base-size);
  font-family: "Inter", sans-serif, "Inter", sans-serif;
  font-family: var(--font-primary), var(--font-fallback);
  color: var(--secondary-color);
  color: var(--color-text);
}

h1,
h2,
h3,
h4,
form legend {
  color: var(--secondary-color);
  margin-bottom: var(--space-xxs);
  line-height: 1.2;
  line-height: var(--heading-line-height);
  font-family: "Inter", sans-serif;
  font-family: var(--font-secondary);
}

/*space*/
h1 {
  margin-bottom: 20px;
  margin-bottom: var(--space-md); /*originaly var(--space-lg)*/
}

h2 {
  margin-bottom: 20px;
  margin-bottom: var(--space-md);
}

p {
  margin-bottom: calc(20px / 2);
  margin-bottom: var(--space-sm);
}

/* text size */
.text--xxxl {
  font-size: calc(
    1em * 1.28 * 1.28 *
      1.28 * 1.28 *
      1.28);
  font-size: var(--text-xxxl);
}

h1,
.text--xxl {
  font-size: calc(
    1em * 1.28 * 1.28 *
      1.28 * 1.28);
  font-size: var(--text-xxl);
}

h2,
.text--xl {
  font-size: calc(
    1em * 1.28 * 1.28 *
      1.28);
  font-size: var(--text-xl);
}

h3,
.text--lg {
  font-size: calc(1em * 1.28 * 1.28);
  font-size: var(--text-lg);
}

h4,
.text--md {
  font-size: calc(1em * 1.28);
  font-size: var(--text-md);
}

h5,
.text--rg {
  font-size: calc(1em * 1.28 + 1.28);
  font-size: var(--text-rg);
}

.text--sm,
small {
  font-size: calc(1em / 1.28);
  font-size: var(--text-sm);
}

.text--xs {
  font-size: calc(1em / (1.28 * 1.28));
  font-size: var(--text-xs);
}

p {
  line-height: 1.4;
  line-height: var(--body-line-height);
}

b,
strong {
  font-weight: bold;
}

h2,
h3,
h4 {
  margin-top: calc(20px / 2);
  margin-top: var(--space-sm);
}

ul,
ol,
p {
  margin-bottom: 20px;
  margin-bottom: var(--space-md);
}

ul,
ol {
  list-style-position: outside;
  padding-left: 24px;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

ul li,
ol li {
  line-height: 1.4;
  line-height: var(--body-line-height);
}

em {
  font-style: italic;
}

u {
  text-decoration: underline;
}

/* utility classes */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: auto;
  cursor: initial;
}

.truncate-wrapper {
  width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* center text */
.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--bold {
  font-weight: bold;
}

.text--italic {
  font-style: italic;
}

.text--red {
  color: var(--red);
}

.text--yellow {
  color: var(--yellow);
}

.text--orange {
  color: var(--orange);
}

.text--green {
  color: var(--green);
}

.text--blue {
  color: var(--blue);
}

.text--white {
  color: var(--primary-color-2);
}

.text--accent-light {
  color: var(--accent-color-light);
}

.text--grey {
  color: var(--grey);
}

.text--black {
  color: var(--secondary-color);
}

/*Media Queries*/
@media only screen and (max-width: 600px) {
  :root {
    --text-base-size: 1em;
    --text-scale-ratio: 1.3;
  }
}

@media only screen and (max-width: 480px) {
  :root {
    --text-base-size: 1em;
    --text-scale-ratio: 1.2;
  }
}

.toast-info {
  background: #fefefe !important;
  color: var(--text-dark) !important;
}

.toast-success {
  background: var(--success) !important;
}

.toast-warning {
  background: var(--warning) !important;
}

.toast-danger {
  background: var(--danger) !important;
}

.toast-black {
  background: var(--neutral-500) !important;
}

/* general */
.Toastify__close-button--default {
  color: var(--neutral-100) !important;
}
.Toastify__toast-container--top-right {
  top: 4em !important;
  z-index: 12;
}
.Toastify__toast {
  /* border-left: 4px solid var(--neutral-100) !important; */
  border-radius: 4px !important;
}

.toast-info > .Toastify__toast-body {
  color: var(--text-dark);
  border-left: 8px solid transparent;
}

.toast-info > .Toastify__close-button--default {
  color: var(--text-dark) !important;
}

.Toastify__toast-body {
  color: var(--neutral-100);
  border-left: 8px solid transparent;
}

body {
  margin: 0;
  background-color: #fafafa;
  color: var(--text-dark);
  overflow-x: hidden;
}

.App {
  overflow-x: hidden;
}

*:focus {
  box-shadow: 0 0 0 4px #5892fd;
  outline: 0;
}

::selection {
  background: var(--primary);
  color: var(--text-light);
}
::-moz-selection {
  background: var(--primary);
  color: var(--text-light);
}

.grid-center {
  display: grid;
  justify-content: center;
  align-items: center;
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: var(--primary);
          text-decoration-color: var(--primary);
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
}

.hl {
  border-bottom: 1px solid #d9d9d9;
}

.vl {
  border-left: 1px solid #d9d9d9;
  padding: 16px 10px;
}

.text-help {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
}

.sideBarActive > div {
  background: #eaeffb;
  color: var(--primary) !important;
}

.nav-active {
  background: var(--primary);
  color: #fff;
}

